<template>
  <div class='apply_detail_wrap'>
    <div class="padding_box custom_bg_white hover_animat">
          <div class="data_table_box">
                <!-- <h2 class="data_table_title">{{programsName}}</h2> -->
                <div class="data_table_top">
                    <div class="data_table_top_left">
                        <div>
                            <Button  icon="md-cloud-upload" type="success" @click="importData.show=true">导入报名名单</Button>
                            <Button  icon="md-download" type="primary" @click="downloadDataFn">导出excel</Button>
                            <Button  icon="md-trash" type="error" @click="deleteFn">删除</Button>
                        </div>
                    </div>
                    <div class="data_table_top_right">
                        <div class="data_table_top_right_block">
                            <Input clearable style="width:200px" v-model="searchData.name" placeholder="请输入报名人员姓名" />
                        </div>
                        <div class="data_table_top_right_block">
                            <Input clearable style="width:200px" v-model="searchData.phone" placeholder="请输入报名人员手机号" />
                        </div>
                        <div class="data_table_top_right_block">
                            <Button  icon="md-search" type="primary" @click="searchFn">搜索</Button>
                        </div>
                    </div>
                </div>
                <div class="table_scroll_wrap">
                    <Table style="min-width:1200px" :loading="tableLoading" stripe :columns="tableColumn" :data="tableData"  @on-selection-change="checkAllGroupChange">
                        <template slot-scope="{row}" slot="operation">
                            <Button  type="warning"   @click="showModifyFn(row)">修改</Button>
                        </template>
                    </Table>
                </div>
                <div class="pagination_wrap">
                    <div class="pagination_wrap">
                        <Page transfer show-elevator show-sizer show-total  :total="totalCount" @on-change="changePageFn" @on-page-size-change="changePageSizeFn" :current="currentPage" :page-size="pageSize" :page-size-opts='pageSizeOptions'/>
                    </div>
                </div>
          </div>
      </div>
      <Drawer  width="700" title="修改报名详情" :closable="true" :mask-closable="false" v-model="modifyData.show" @on-close="modifyData.show=false">
            <div class="drawer_scroll_wrap">
                <div class="edit_wrap">
                    
                    <div class="edit_item">
                        <div class="edit_item_left">
                            <h4>人员姓名</h4>
                        </div>
                        <div class="edit_item_right">
                            <Input v-model="modifyData.data.name"  style="width: 100%" @on-blur="handleBlurFn( 'name')"/>
                            <span :class="modifyData.warning.name ? 'warning' : ''">{{ modifyData.text.name }}</span>
                        </div>
                    </div>
                    <div class="edit_item">
                        <div class="edit_item_left">
                            <h4>手机号</h4>
                        </div>
                        <div class="edit_item_right">
                            <Input v-model="modifyData.data.phone"  style="width: 100%" @on-blur="handleBlurFn( 'phone')"/>
                            <span :class="modifyData.warning.phone ? 'warning' : ''">{{ modifyData.text.phone }}</span>
                        </div>
                    </div>
                
                    <div class="edit_item">
                        <div class="edit_item_left">
                            <h4>报名时间</h4>
                        </div>
                        <div class="edit_item_right">
                            <DatePicker transfer style="width: 100%" type="datetime" format="yyyy-MM-dd HH:mm:ss" v-model="modifyData.data.applyTime" @on-change="changeTimeFn($event, 'applyTime')" placeholder="报名时间"></DatePicker>
                            <span :class="modifyData.warning.applyTime ? 'warning' : ''">{{ modifyData.text.applyTime }}</span>
                        </div>
                    </div>
                    
                    <div class="edit_item">
                        <div class="edit_item_left">
                            <h4>工作单位</h4>
                        </div>
                        <div class="edit_item_right">
                            <Input v-model="modifyData.data.company"  style="width: 100%" @on-blur="handleBlurFn('company')"/>
                            <span :class="modifyData.warning.company ? 'warning' : ''">{{ modifyData.text.company }}</span>
                        </div>
                    </div>
                    <div class="edit_item">
                        <div class="edit_item_left">
                            <h4>职务</h4>
                        </div>
                        <div class="edit_item_right">
                            <Input v-model="modifyData.data.duty"  style="width: 100%"/>
                            <span :class="modifyData.warning.duty ? 'warning' : ''">{{ modifyData.text.duty }}</span>
                        </div>
                    </div>
                    <div class="edit_item">
                        <div class="edit_item_left">
                            <h4>专业</h4>
                        </div>
                        <div class="edit_item_right">
                            <Input v-model="modifyData.data.major"  style="width: 100%"/>
                            <span :class="modifyData.warning.major ? 'warning' : ''">{{ modifyData.text.major }}</span>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="drawer_submit_wrap">
                <Button type="primary"  @click="modifyFn">立即修改</Button>
            </div>
        </Drawer>
        <Modal  title="导入报名名单" v-model="importData.show" :mask-closable="false" width="700" @on-cancel="importData.show = false;">
            <div class="modal_scroll_wrap">
                
                <div style="margin-bottom:20px">
                    <h4 style="margin-bottom:10px;">1.下载导入模板</h4>
                    <Button  icon="md-download" type="info" @click="downloadTemplateFn">下载模板</Button>
                </div>
                <div>
                    <h4 style="margin-bottom:10px;">2.上传报名人员名单excel模板</h4>
                    <div style="text-align: center;">
                        <Upload :before-upload="beforeUploadFn" action=""  type="drag" :format="['xlsx']" accept="xlsx">
                            
                            <div style="padding:70px 0;">
                                <Button icon="md-cloud-upload" type="success">上传模板文件</Button>
                            </div>
                        </Upload>
                        <h3 v-if="uploadFile">{{ uploadFile.name }}</h3>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <Button type="primary"  @click="importFn">确定导入</Button>
            </div>
        </Modal>
        <Modal v-model="statusModal.show" title="" :footer-hide="true" :mask-closable="false" :closable="false" width="360">
            <div style="text-align:center;padding:20px 0">
                <Spin fix v-if="!statusModal.showSuccess && !statusModal.showError">
                    <Icon type="ios-loading" size=18 class="spin-icon-rotate"></Icon>
                    <div>{{ statusModal.text }}</div>
                </Spin>
                <Spin fix v-if="statusModal.showSuccess" style="color:#00ad19">
                    <Icon type="ios-checkmark-circle" size=18 />
                    <div>{{ statusModal.successText }}</div>
                </Spin>
                <Spin fix v-if="statusModal.showError" style="color:#f72b2b">
                    <Icon type="ios-close-circle" size=18 />
                    <div>{{ statusModal.errorText }}</div>
                </Spin>
            </div>
        </Modal>
  </div>
</template>

<script>
import Vue from "vue"
import { Table ,Tag ,Icon,Page,Input,Button,Spin,Modal,Upload,Drawer,DatePicker} from 'view-design';
Vue.component('Table', Table);
Vue.component('Tag', Tag);
Vue.component('Icon', Icon);
Vue.component('Page', Page);
Vue.component('Input', Input);
Vue.component('Button', Button);
Vue.component('Spin', Spin);
Vue.component('Modal', Modal);
Vue.component('Upload', Upload);
Vue.component('Drawer', Drawer);
Vue.component('DatePicker', DatePicker);
import NET_PORT from "../../../api/port.js"
var moment = require('moment');
export default {
  name:'apply_detail',
  components:{},
  props:{},
  data(){
    return {
        uploadFile:null,
        importData:{
            show:false,
        },
        currentModifyId:'',
        modifyData:{
            show:false,
            data:{
                name:'',
                phone:'',
                company:'',
                duty:'',
                major:'',
                applyTime:null,
            },
            verify: {
                name: true,
                phone: true,
                company: true,
                duty: false,
                major: false,
                applyTime: true,
            },
            text: {
                name: '',
                phone: '',
                company: '',
                duty: '',
                major: '',
                applyTime: '',
            },
            warning: {
                name: false,
                phone: false,
                company: false,
                duty: false,
                major: false,
                applyTime: false,
            },
        },
        person:this.$store.state.user,
        programsId:'',
        programsName:'',
        firstEnter:true,
        statusModal:{
            show:false,
            showSuccess:false,
            showError:false,
            text:'正在上传',
            successText:'上传成功！',
            errorText:'上传失败！',
        },
        totalCount: 0,
        pageSize: 10,
        currentPage: 1,
        pageSizeOptions:[10,20,50,100],
        tableLoading:false,
        searchData:{
            name:'',
            phone:'',
        },

        tableColumn:[
             {
                type: 'selection',
                width: 60,
                align: 'center'
            },
            {
                title: '序号',
                key: 'no',
                width:80,
            },
           
            {
                title: '姓名',
                key: 'name',
            },
            {
                title: '联系电话',
                key: 'phone',
            },
            {
                title: '工作单位',
                key: 'organization',
            },
            {
                title: '职务',
                key: 'duty',
            },
            {
                title: '专业',
                key: 'specialty',
            },

            {
                title: '报名时间',
                key: 'applyTime',
            },
            {
                title: '最近签到时间',
                key: 'signIn',
            },
           
            {
                title: '最近签退时间',
                key: 'signOut',
            },
            {
                title: '操作',
                key: 'operation',
                slot: 'operation',
                width: 250,
                align: 'center',
            },
      ],

      tableData:[],

    }
  },
  watch:{},
  computed:{},
  methods:{
    beforeUploadFn(file){
        this.uploadFile = file;
        return false;
    },
    importFn(){
        if(this.uploadFile){
            const formData = new FormData()
            const file = this.uploadFile
            formData.append('file', file)
            this.statusModal.show = true;
            this.statusModal.text = '正在导入';
            this.statusModal.successText = '导入成功！';
            this.statusModal.errorText = '导入失败！';
            this.$axios.post(NET_PORT.applyPersonImport+'?projectID='+this.programsId, formData)
            .then((res) => {
                if (res.data.code === 200) {
                    this.successFn();
                    this.importData.show=false;
                    this.tableData = [];
                    this.totalCount = 0
                    this.currentPage = 1
                    this.getDataFn();
                } else {
                    this.$Message.error({
                        content: res.data.msg,
                        duration: 2,
                    })
                    this.errorFn();
                }
            })
            .catch(() => {
                this.errorFn();
                this.$Message.error({
                    content: "网络错误！",
                    duration: 2,
                })
            })

        }else{
            this.$Message.error({
                content: "请先上传excel模板",
                duration: 2,
            })
        }
        
    },

    downloadTemplateFn(){
        this.$axios.get(NET_PORT.templateDownload, { responseType: 'blob' })
        .then((res) => {
            let that = this;
            let blob = res.data;
            let reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = function (e) {
                let a = document.createElement("a");
                a.download = "学员报名名单模板.xlsx";
                a.href = e.target.result;
                document.documentElement.appendChild(a);
                document.documentElement.removeChild(a)
                a.click();
            };
        })
        .catch(() => {
            this.$Message.error({
                content: "网络错误！",
                duration: 2,
            })
        })
    },
    deleteFn(){
        if (!this.selection.length) {
            this.$Message.warning({
                title: '提示！',
                content: '请至少选择一项！'
            })
        } else {
            this.$Modal.confirm({
                title: '删除确认！',
                content: '<h3 style="color:#ed4014">您确定要删除这' + this.selection.length + '条数据吗？</h3>',
                onOk: () => {
                    // 显示出正在上传
                    this.statusModal.show = true;
                    this.statusModal.text = '正在删除';
                    this.statusModal.successText = '删除成功！';
                    this.statusModal.errorText = '删除失败！';
                    this.certainDeleteFn();
                }
            });
        }
    },
    certainDeleteFn() {
        let bodyData = []
        this.selection.forEach((item, index) => {
            bodyData.push(item.id)
        })

        this.$axios.post(NET_PORT.applyPersonDelete, bodyData)
        .then((res) => {

            if (res.data.code === 200) {
                this.successFn();
                this.tableData = [];
                this.totalCount = 0
                this.currentPage = 1
                this.getDataFn();
            } else {
                this.$Message.error({
                    content: res.data.msg,
                    duration: 2,
                })
                this.errorFn();
            }
        })
        .catch(() => {
            this.errorFn();
            this.$Message.error({
                content: "网络错误！",
                duration: 2,
            })
        })
    },

    checkAllGroupChange(selection){
        this.selection=selection;
    },
    changePageFn(page){
        this.currentPage=page;
        this.getDataFn();
    },
    changePageSizeFn(size){
      this.currentPage=1;
      this.pageSize=size;
      this.getDataFn();
    },
    searchFn(){
        this.currentPage=1;
        this.getDataFn();
    },

    downloadDataFn(){
        let bodyData={
            projectID:this.programsId,
            applyPerson:'',
            phone:'',
            workUnit:'',
            duty:'',
            wxUserName:'',
        }
        // let urlData={
        //     startTime:this.queryDataNow.getTimeStart ? this.queryDataNow.getTimeStart : '',
        //     endTime:this.queryDataNow.getTimeEnd ? this.queryDataNow.getTimeEnd : '',
           
        // }
        // let paramsStr='?'+this.paramsStrFn(urlData)

        this.$axios.post(NET_PORT.applyPersonExport,bodyData,{responseType: 'blob'})
        .then((res)=>{
            let that=this;
            let blob = res.data;
            let reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = function (e) {
                let a = document.createElement("a");
                a.download = "学员报名表.xls";
                a.href = e.target.result;
                document.documentElement.appendChild(a);
                document.documentElement.removeChild(a)
                a.click();
            };
        })
        .catch(()=>{
            this.$Message.error({
                    content:"网络错误！",
                    duration:2,
            })
        })
    },

    paramsStrFn(params){
        let paramsStr = '';
        for(let key in params){
            paramsStr += key + '=' + params[key] + '&'
        }
        paramsStr = paramsStr.slice(0,-1);
        return paramsStr
    },

    successFn(){
        this.statusModal.showSuccess=true;
        setTimeout(()=>{
            this.statusModal.show=false;
            setTimeout(()=>{
                this.statusModal.showSuccess=false;
                this.statusModal.showError=false;
            },500)
        },1000)
    },

    errorFn(){
        this.statusModal.showError=true;
        setTimeout(()=>{
            this.statusModal.show=false;
            setTimeout(()=>{
                this.statusModal.showSuccess=false;
                this.statusModal.showError=false;
            },500)
        },1000)
    },
    formatTimeFn(str){
        let resultArr=[];
        let arr=str.split(',');
        arr.forEach((item,index)=>{
            if(arr.length===1){
                resultArr.push((item.split('[')[1]).split(']')[0])
            }else{
                if(index===0){
                    resultArr.push(item.split('[')[1])
                }else if(index===arr.length-1){
                    resultArr.push(item.split(']')[0].trim())
                }else{
                    resultArr.push(item.trim())
                }
            }
        })
        return resultArr[resultArr.length-1]
    },

    showModifyFn(obj) {
        this.modifyData.data.name=obj.name;
        this.modifyData.data.phone=obj.phone;
        this.modifyData.data.company=obj.organization;
        this.modifyData.data.duty=obj.duty;
        this.modifyData.data.major=obj.specialty;
        this.modifyData.data.applyTime=obj.applyTime;
        this.modifyData.show = true;
        this.currentModifyId = obj.id;
      
    },
    modifyFn(){
        this.statusModal.show = true;
        this.statusModal.text = '正在修改';
        this.statusModal.successText = '修改成功！';
        this.statusModal.errorText = '修改失败！';
        let bodyData = {
            id: this.currentModifyId,
            applyPerson:this.modifyData.data.name,
            phone:this.modifyData.data.phone,
            workUnit:this.modifyData.data.company,
            duty:this.modifyData.data.duty,
            professional:this.modifyData.data.major,
            applyTime: moment(this.modifyData.data.applyTime).format('YYYY-MM-DD HH:mm:ss'),
        }
        
        this.$axios.post(NET_PORT.applyPersonUpdate, bodyData)
        .then((res) => {
            if (res.data.code === 200) {
                this.successFn();
                this.modifyData.show = false;
                this.tableData = [];
                this.totalCount = 0
                this.currentPage = 1
                this.getDataFn();
                
            } else {
                this.$Message.error({
                    content: res.data.msg,
                    duration: 2,
                })
                this.errorFn();
            }
        })
        .catch(() => {
            this.errorFn();
            this.$Message.error({
                content: "网络错误！",
                duration: 2,
            })
        })
    },
    changeTimeFn(arr, type){
        this.handleBlurFn(type)
    },

    handleBlurFn( key) {
        let obj = 'modifyData';
        let name = key;
        let text = "";
        let warning = false;
        if (key === 'name') {
            if (this[obj].data.name.trim() === '') {

                text = '姓名不能为空！'
                warning = true;
                this[obj].verify[name] = false
            } else {
                text = ''
                warning = false;
                this[obj].verify[name] = true
            }

        } else if (key === 'phone') {
            if (this[obj].data.phone.trim() === '') {

                text = '手机号不能为空！'
                warning = true;
                this[obj].verify[name] = false
            } else {
                text = ''
                warning = false;
                this[obj].verify[name] = true
            }

        } else if (key === 'applyTime') {
            if (this[obj].data.applyTime === null || this[obj].data.applyTime[0] === '') {

                text = '请选择报名时间！'
                warning = true;
                this[obj].verify[name] = false
            } else {
                text = ''
                warning = false;
                this[obj].verify[name] = true
            }

        } else if (key === 'company') {
            if (this[obj].data.company === '') {

                text = '工作单位不能为空'
                warning = true;
                this[obj].verify[name] = false
            } else {
                text = ''
                warning = false;
                this[obj].verify[name] = true
            }

        }

        this[obj].text[name] = text;
        if (warning) {
            this[obj].warning[name] = true
        } else {
            this[obj].warning[name] = false
        }
    },
    getDataFn(){
        this.tableLoading=true;
        this.selection=[];
       
        let bodyData={
            projectID:this.programsId,
            applyPerson:this.searchData.name,
            phone:this.searchData.phone,
            workUnit:'',
            duty:'',
            wxUserName:''
        }

        let urlData={
            pageIndex:this.currentPage,
            pageSize:this.pageSize,
        }

        let paramsStr='?'+this.paramsStrFn(urlData)
        this.$axios.post(NET_PORT.applyPersonList+paramsStr,bodyData)
        .then((res)=>{
            if(res.data.code===200){
                this.tableData=[];
                for(let i=0;i<res.data.data.length-1;i++){
                    // if(i===0){
                    //     this.programsName=res.data.data[i].projectName
                    // }
                    let newestSignIn=res.data.data[i].signTime ? this.formatTimeFn(res.data.data[i].signTime): '';
                    let newestSignOut=res.data.data[i].backTime ? this.formatTimeFn(res.data.data[i].backTime): '';

                    this.tableData.push({
                        no:i+1,
                        id:res.data.data[i].id,
                        name:res.data.data[i].applyPerson,
                        phone:res.data.data[i].phone,
                        organization:res.data.data[i].workUnit,
                        duty:res.data.data[i].duty,
                        specialty:res.data.data[i].professional,
                        applyTime:res.data.data[i].applyTime,
                        signIn:newestSignIn,
                        signOut:newestSignOut,
                        wxNickName:res.data.data[i].wxUserName,
                        openId:res.data.data[i].openid,
                    })
                }
                this.totalCount=Number(res.data.data[res.data.data.length-1].total);
                this.tableLoading=false;
                if(this.firstEnter){
                    this.firstEnter=false;
                    this.$Loading.finish();
                }
            }else{
                this.$Message.error({
                    content:res.data.msg,
                    duration:2,
                })
            }
        })
        .catch(()=>{
            this.$Message.error({
                    content:"网络错误！",
                    duration:2,
            })
        })


    },
  },
  created(){
    this.programsId=this.$route.query.id
    this.$Loading.start();
    this.getDataFn();
  },
  mounted(){}
}
</script>
<style lang="scss" scoped>
</style>